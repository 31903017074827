var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateGeneral($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.general.id),expression:"general.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.general.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.general, "id", $event.target.value)}}}),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Name","label-for":"name-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.general.name.$error,
        },attrs:{"placeholder":"name","state":_vm.validateState('name')},model:{value:(_vm.$v.general.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.general.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.general.name.$model"}}),(_vm.submitted && !_vm.$v.general.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" name is required ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Logo","label-for":"general-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[(!_vm.general.logo)?_c('div',[_c('b-form-file',{attrs:{"id":"general-input","accept":"image/jpeg, image/png, image/jpg","placeholder":"Choose a logo or drop it here..."},on:{"change":function($event){return _vm.onFileChange($event, 'logo')}}})],1):_c('div',[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.general.logo,"width":"150","height":"150"}}),_c('button',{staticClass:"btn social-btn btn-rounded btn-danger mr-4",on:{"click":function($event){return _vm.removeImage('logo')}}},[_c('i',{staticClass:"mdi mdi-close"})])])]),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Email","label-for":"email-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.general.email.$error,
        },attrs:{"placeholder":"Enter email address","state":_vm.validateState('email')},model:{value:(_vm.$v.general.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.general.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.general.email.$model"}}),(_vm.submitted && !_vm.$v.general.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" email address is required ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Address","label-for":"address-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-form-textarea',{attrs:{"id":"address-1-input","placeholder":"Enter address...","rows":"5","max-rows":"8"},model:{value:(_vm.general.address),callback:function ($$v) {_vm.$set(_vm.general, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"general.address"}})],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Phone Number","label-for":"phone-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.general.phone.$error,
        },attrs:{"placeholder":"phone Number","state":_vm.validateState('phone')},model:{value:(_vm.$v.general.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.general.phone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.general.phone.$model"}}),(_vm.submitted && !_vm.$v.general.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" phone number is required ")]):_vm._e(),(!_vm.$v.general.phone.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name must have at least "+_vm._s(_vm.$v.general.phone.$params.minLength.min)+" letters. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Google Key","label-for":"googlekey-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{attrs:{"placeholder":"google secret key"},model:{value:(_vm.general.google_key),callback:function ($$v) {_vm.$set(_vm.general, "google_key", $$v)},expression:"general.google_key"}})],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Tax (%)","label-for":"tax-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.general.tax.$error,
        },attrs:{"placeholder":"Enter tax percent number","state":_vm.validateState('tax')},model:{value:(_vm.$v.general.tax.$model),callback:function ($$v) {_vm.$set(_vm.$v.general.tax, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.general.tax.$model"}}),(_vm.submitted && !_vm.$v.general.tax.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" tax is required ")]):_vm._e(),(!_vm.$v.general.tax.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name must have at least "+_vm._s(_vm.$v.general.tax.$params.minLength.min)+" letters. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Fee","label-for":"fee-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{attrs:{"type":"number","min":"0","step":"0.01","pattern":"^\\d+(?:\\.\\d{1,2})?$","placeholder":"Enter fee"},model:{value:(_vm.general.fee),callback:function ($$v) {_vm.$set(_vm.general, "fee", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"general.fee"}})],1),_c('b-form-group',{staticClass:"col-md-6 offset-md-4"},[_c('b-button',{staticClass:"btn btn-success btn-lg text-center",attrs:{"type":"submit"}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }